import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {InvitationModel} from 'src/app/types/types';
import {IndexerGetInvitationsGQL} from 'src/graphql/gen/indexer';
import {UserApiService} from './user-api.service';

@Injectable({
  providedIn: 'root',
})
export class InvitationApiService {
  constructor(readonly getInvitations: IndexerGetInvitationsGQL, readonly userApiService: UserApiService) {}

  getUserInvitations(userAddress: string, status = 'invited'): Observable<InvitationModel[]> {
    return this.getInvitations.fetch({where: {collaborator_address: {_eq: userAddress}, status: {_eq: status}}}, {fetchPolicy: 'no-cache'}).pipe(
      map(res => {
        const invitations = res.data.invitation;
        return invitations.map(invitation => ({
          ...invitation,
          holder: this.userApiService.extendUser(invitation.holder),
          fa: {
            ...invitation.fa,
            creator: this.userApiService.extendUser(invitation.fa.creator),
          },
        }));
      }),
    );
  }
}
