import {Injectable} from '@angular/core';
import {GraphCmsGetPageGQL, GraphCmsLocale} from '../../../graphql/gen/graph-cms';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PageApiService {
  constructor(private getPageByIdGql: GraphCmsGetPageGQL) {}

  private getPageById(id: string) {
    return this.getPageByIdGql.fetch({id, locales: [GraphCmsLocale.En]}).pipe(map(res => res.data.page));
  }

  getFaq() {
    return this.getPageById('ckzwu3mk8jhwh0b48bkwwl0ci');
  }

  getTokenMigration() {
    return this.getPageById('cl6qsvqjyu2cq0bulb8pif5qe');
  }

  getTrustScore() {
    return this.getPageById('cl0kxsuowu1r70cxl8d4ff8gs');
  }

  getImprint() {
    return this.getPageById('cl9sm3h8ejfbb0at6oq2r57cg');
  }

  getTerms() {
    return this.getPageById('cl5s1yppkhsle0bukg944mvpu');
  }

  getPage(page: string) {
    switch (page) {
      case 'faq':
        return this.getFaq();
      case 'imprint':
        return this.getImprint();
      case 'terms':
        return this.getTerms();
      default:
        throw new Error('no page found');
    }
  }
}
