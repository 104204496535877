import {Injectable} from '@angular/core';
import {BackendGetBookmarksGQL, BackendUpdateBookmarkGQL} from '../../../graphql/gen/backend';
import {map, tap} from 'rxjs/operators';
import {SigningService} from '../tezos/signing.service';

@Injectable({
  providedIn: 'root',
})
export class BookmarksApiService {
  constructor(
    private readonly bookmarksGql: BackendGetBookmarksGQL,
    private readonly updateBookmarkGql: BackendUpdateBookmarkGQL,
    private readonly signingService: SigningService,
  ) {}

  bookmarks() {
    if (!this.signingService.isSigned$.value) {
      return;
    }
    return this.bookmarksGql.fetch().pipe(map(res => res.data.user?.tokenBookmarks || []));
  }

  add(contract, tokenId) {
    if (!this.signingService.isSigned$.value) {
      return;
    }
    return this.updateBookmarkGql.mutate(
      {
        contract,
        tokenId,
        bookmarked: true,
      },
      {fetchPolicy: 'no-cache'},
    );
  }

  remove(contract, tokenId) {
    if (!this.signingService.isSigned$.value) {
      return;
    }
    return this.updateBookmarkGql.mutate(
      {
        contract,
        tokenId,
        bookmarked: false,
      },
      {fetchPolicy: 'no-cache'},
    );
  }
}
