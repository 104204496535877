import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {BookmarksApiService} from './api/bookmarks-api.service';
import {SigningService} from './tezos/signing.service';

export interface IBookmark {
  contract: string;
  tokenId: string;
}

@Injectable({
  providedIn: 'root',
})
export class BookmarkService {
  private _bookmarks$ = new BehaviorSubject<IBookmark[]>([]);

  constructor(private bookmarkApiService: BookmarksApiService, private signingService: SigningService) {
    signingService.isSigned$.subscribe(isSigned => {
      if (isSigned) bookmarkApiService.bookmarks()?.subscribe(this.prepareBookmark());
    });
  }

  get bookmarks$() {
    return this._bookmarks$;
  }

  refresh() {
    this.bookmarkApiService.bookmarks()?.subscribe(this.prepareBookmark());
  }

  add(contract: string, tokenId: string) {
    return this.bookmarkApiService.add(contract, tokenId)?.subscribe(_ => {
      const nextBookmarks = [...this._bookmarks$.value];
      if (!nextBookmarks.find(bookmark => bookmark.tokenId === tokenId && bookmark.contract === contract)) {
        nextBookmarks.unshift({contract, tokenId});
      }
      this._bookmarks$.next(nextBookmarks);
    });
  }

  remove(contract: string, tokenId: string) {
    return this.bookmarkApiService.remove(contract, tokenId)?.subscribe(_ => {
      const nextBookmarks = this._bookmarks$.value.filter(bookmark => !(bookmark.contract === contract && bookmark.tokenId === tokenId));
      this._bookmarks$.next(nextBookmarks);
    });
  }

  isBookmarked(contract: string, tokenId: string) {
    return this._bookmarks$.value.findIndex(bookmark => bookmark.tokenId === tokenId && bookmark.contract === contract) !== -1;
  }

  private prepareBookmark() {
    return response => this._bookmarks$.next(response.map(bookmark => bookmark as IBookmark));
  }
}
